<template>
  <div>
    <b-alert class="w-100" variant="warning" fade show>
      <!-- <h4 class="alert-heading">Danger</h4> -->
      <div class="alert-body text-center">
        <feather-icon icon="InfoIcon" class="mr-50" />
        <span>สมัครสมาชิก</span>
      </div>
    </b-alert>
    <div class="d-flex justify-content-center">
      <b-card class="col-12 col-xl-6">
        <b-card-text>
          <validation-observer ref="submitForm">
            <b-overlay :show="playLoad" spinner-variant="primary" spinner-type="grow" spinner-small rounded="sm" no-wrap />
            <b-form-group class="text-center" description="250 x 250">
              <!-- <b-img :src="imageConvertUrl(addAccount.imageProfile)" rounded="circle" style="height: 250px; width: 250px" thumbnail fluid /> -->
              <b-avatar :src="imageConvertUrl(addAccount.imageProfile)" size="250" variant="light-primary" />
            </b-form-group>
            <b-form>
              <b-row>
                <b-col cols="12">
                  <b-form-group label="รูปโปรไฟล์" label-cols-md="4">
                    <b-form-file v-model="addAccount.imageProfile" accept="image/*" type="file" placeholder="Choose a file or drop it here..." drop-placeholder="Drop image here..." />
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <b-form-group label="ชื่อผู้ใช้งาน (Username)" label-cols-md="4" description="ชื่อผู้ใช้งาน ใช้ตัวอักษร (A-Z,a-z) เเละ ตัวเลข (0-9) เท่านั้น">
                    <validation-provider #default="{ errors }" name="ชื่อผู้ใช้งาน (Username)" rules="required">
                      <b-form-input v-model="addAccount.username" :state="errors.length > 0 ? false : null" placeholder="ชื่อผู้ใช้งาน (Username)" />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <b-form-group label="รหัสผ่าน (Password)" label-cols-md="4" description="รหัสผ่าน อย่างน้อย 8 ตัว ">
                    <validation-provider #default="{ errors }" name="รหัสผ่าน (Password)" rules="required|min:8">
                      <b-input-group>
                        <b-form-input v-model="addAccount.password" :type="passwordFieldType" :state="errors.length > 0 ? false : null" placeholder="รหัสผ่าน (Password)" />
                        <b-input-group-append is-text>
                          <feather-icon class="cursor-pointer" :icon="passwordToggleIcon" @click="togglePasswordVisibility" />
                        </b-input-group-append>
                      </b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <b-form-group label="เบอร์โทร" label-cols-md="4">
                    <validation-provider #default="{ errors }" name="เบอร์โทร" rules="required|integer|min:10">
                      <b-form-input v-model="addAccount.phone" :state="errors.length > 0 ? false : null" placeholder="เบอร์โทร" />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <b-form-group label="บ้านเลขที่" label-cols-md="4">
                    <validation-provider #default="{ errors }" name="บ้านเลขที่" rules="required">
                      <b-form-input v-model="addAccount.houseNo" :state="errors.length > 0 ? false : null" placeholder="บ้านเลขที่" />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <b-form-group label="ที่อยู่" label-cols-md="4">
                    <validation-provider #default="{ errors }" name="ที่อยู่" rules="required">
                      <b-form-textarea v-model="addAccount.address" :state="errors.length > 0 ? false : null" placeholder="ที่อยู่" rows="3" />
                      <!-- <b-form-input v-model="addAccount.nameMeet" :state="errors.length > 0 ? false : null" placeholder="ที่อยู่" /> -->
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <b-form-group label="tag" label-cols-md="4">
                    <b-form-tags v-model="addAccount.tag" class="mb-2" />
                  </b-form-group>
                </b-col>

                <!-- submit and reset -->
                <b-col offset-md="4">
                  <b-button @click.prevent="submitForm()" type="submit" variant="primary" class="mr-1"> บันทึก </b-button>
                  <b-button @click="resetForm()" type="reset" variant="outline-secondary"> รีเซ็ต </b-button>
                </b-col>
              </b-row>
            </b-form>
          </validation-observer>
        </b-card-text>
      </b-card>
    </div>
  </div>
</template>

<script>
  import { ValidationProvider, ValidationObserver, localize } from 'vee-validate';
  import { required, alphaDash, min, integer } from '@validations';
  import { togglePasswordVisibility } from '@core/mixins/ui/forms';
  import API from '@/connect/config';
  import axios from 'axios';

  // import dayjs from 'dayjs';
  // import 'dayjs/locale/th';
  // import utc from 'dayjs/plugin/utc';
  // import timezone from 'dayjs/plugin/timezone';
  // dayjs.extend(utc);
  // dayjs.extend(timezone);
  // const buddhistEra = require('dayjs/plugin/buddhistEra');
  // dayjs.extend(buddhistEra);

  export default {
    name: 'user_regis', //ลงทะเบียนผู้ใช้งาน
    components: {
      ValidationProvider,
      ValidationObserver,
    },
    data() {
      return {
        // ? VeeValidate
        required,
        alphaDash,
        min,
        integer,
        //? locale
        locale: 'th',
        // ? สร้างนัดหมาย
        addAccount: {
          imageProfile: null, //รูปโปรไฟล์
          username: '', //username
          password: '', //password
          phone: '', //เบอร์โทร
          houseNo: '', //บ้านเลขที่
          address: '', //ที่อยู่
          tag: [], //tag
          userId: '',
          rule: 'member',
          editMode: false,
          liffId: '',
          lineId: '',
          lineName: '',
        },
        // ? loading
        playLoad: false,
      };
    },
    mixins: [togglePasswordVisibility],
    computed: {
      passwordToggleIcon() {
        return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon';
      },
    },

    created() {
      // ? Validat thai
      localize(this.locale);
    },
    methods: {
      async submitForm() {
        this.playLoad = true;
        const { userId, liffId } = JSON.parse(localStorage.getItem('company_jwt_vis'));
        const { line_name, line_userId } = JSON.parse(localStorage.getItem('info_line'));
        const { username } = JSON.parse(localStorage.getItem('profile_visitor'));
        if (userId) {
          this.addAccount.userId = userId;
          this.addAccount.liffId = liffId;
          this.addAccount.lineName = line_name;
          this.addAccount.lineId = line_userId;
          this.addAccount['edit_username_regis'] = `${this.addAccount.username}@${username.split('@')[1]}`;
        }
        this.$refs.submitForm.validate().then(async (success) => {
          if (success) {
            console.log(this.addAccount);
            const { imageProfile, edit_username_regis, password, phone, houseNo, address, tag, userId, rule, editMode, liffId, lineId, lineName } = this.addAccount;
            const _data = new FormData();
            _data.append('userId', userId);
            _data.append('imageProfile', imageProfile);
            _data.append('username', edit_username_regis);
            _data.append('password', password);
            _data.append('phone', phone);
            _data.append('houseNo', houseNo);
            _data.append('address', address);

            if (tag != null) {
              tag.forEach((el) => {
                _data.append('tag[]', el);
              });
            }
            _data.append('rule', rule);
            _data.append('editMode', editMode);
            _data.append('liffId', liffId);
            _data.append('lineId', lineId);
            _data.append('lineName', lineName);

            axios
              .put(`${API}addAccount`, _data)
              .then(async (res) => {
                console.log(res);
                //this.$router.push({ name: 'login' });
                this.$store.commit('setToastMessage', {
                  message: `${res.data.message}`,
                  title: 'ลงทะเบียนผู้ใช้งาน',
                  variant: 'primary',
                });
                this.$swal({
                  title: 'ลงทะเบียนสำเร็จ',
                  text: 'กดปิดเบราว์เซอร์ แล้วเข้าจาก rich menu ใหม่',
                  type: 'success',
                  customClass: {
                    confirmButton: 'btn btn-primary',
                    cancelButton: 'btn btn-outline-danger ml-1',
                  },
                });
              })
              .catch(async (error) => {
                console.log(error.response);
                this.$store.commit('setToastMessage', {
                  message: `${error.response.data.message}`,
                  title: 'ลงทะเบียนผู้ใช้งาน',
                  variant: 'danger',
                });
              });

            this.playLoad = false;
          } else {
            // re-validate to re-generate the messages.
            this.$refs.submitForm.validate();

            this.playLoad = false;
          }
        });
      },
      resetForm() {
        this.addAccount = {
          imageProfile: null, //รูปโปรไฟล์
          username: '', //username
          password: '', //password
          phone: '', //เบอร์โทร
          houseNo: '', //บ้านเลขที่
          address: '', //ที่อยู่
          tag: [], //tag
          // userId: '',
          // rule: 'member',
          // editMode: false,
          // liffId: '',
          // lineId: '',
          // lineName: '',
        };

        this.$nextTick(() => {
          this.$refs.submitForm.reset();
        });
      },
      imageConvertUrl(image) {
        if (image) {
          const url = window.URL.createObjectURL(image);
          return url;
        }
      },
    },
  };
</script>

<style></style>
